import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import moment from 'moment';
import React, { useEffect, FC } from 'react';
import styled from 'styled-components';
import App from '../components/App';
import Page from '../components/Page';
import { Menu } from '../components/Menu';
import { Titles } from '../components/Titles';

export default function Template({ data }) {
  const { page } = data;
  const { slug, title, date, parent } = page;
  const { body } = parent;

  return (
    <App>
      <Page title={title}>
        <Menu></Menu>
        <Titles>
          <h1>{title}</h1>
          {date && <h3>Updated {date}</h3>}
        </Titles>
        <MDXRenderer>{body}</MDXRenderer>
      </Page>
    </App>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    page(id: { eq: $id }) {
      slug
      title
      date(formatString: "D MMMM YYYY")
      parent {
        ... on Mdx {
          body
        }
      }
    }
  }
`;
